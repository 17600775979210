import React from 'react'
import { graphql } from 'gatsby'

import Layout from './layout'
import AboutText from '../components/AboutText'
import ProjectList from '../components/ProjectList'

const CategoryPage = ({
  data: {
    allDatoCmsProject: {
      nodes: allProjects
    },
    datoCmsCategory: {
      featuredProjects
    }
  },
  location,
  pageContext
}) => {
  return (
    <Layout location={location} pageContext={pageContext} isCategory={true}>
      <AboutText/>
      <ProjectList projects={mergeProjects(featuredProjects, allProjects)}/>
    </Layout>
  )
}

function mergeProjects (featuredProjects, allProjects) {
  const result = featuredProjects.concat(allProjects)
  return result
    .map(e => e.originalId)
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter(e => result[e]).map(e => result[e])
}

export const query = graphql`
  query($categoryName: String!) {
    datoCmsCategory(name: {eq: $categoryName}) {
      featuredProjects {
        originalId
        displayName
        clientName
        slug
        hoverImage {
          fluid(imgixParams: {
            h: "333",
            w: "500",
            ar: "1.5",
            fm: "jpg",
            auto: "compress",
            fit: "crop",
            crop: "edges"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
    allDatoCmsProject(filter: {category: {elemMatch: {name: {eq: $categoryName}}}}) {
      nodes {
        originalId
        displayName
        clientName
        slug
        hoverImage {
          fluid(imgixParams: {
            h: "333",
            w: "500",
            ar: "1.5",
            fm: "jpg",
            auto: "compress",
            fit: "crop",
            crop: "edges"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`

export default CategoryPage
